// index.js v.0.0.69.002
import Script from 'next/script';
import Head from 'next/head';
import Image from 'next/image';
import styles from '../styles/Home.module.css';
import LandingPage from '../components/LandingPage';

export default function Home() {
    return (
        <>
            {/* Same tag on every page */}
            <Script
                async
                src="https://www.googletagmanager.com/gtag/js?id=AW-692819199"
                strategy="afterInteractive"
            />
            <Script id="gtag-common" strategy="afterInteractive">
                {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-692819199');
      `}
            </Script>

            <LandingPage />
        </>
    );
}
